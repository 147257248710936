export const IconNostr = () => (
  <svg
    version='1.1'
    id='_8'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    viewBox='0 0 24 24'
    width='30'
    height='30'>
    <g>
      <path
        fill='none'
        d='M0,0v24h24V0H0z M21.67,14.93c0,1.94,0,2.92-0.33,3.96c-0.41,1.14-1.31,2.04-2.46,2.46 c-1.04,0.33-2.01,0.33-3.96,0.33H9.07c-1.94,0-2.92,0-3.96-0.33c-1.14-0.41-2.04-1.31-2.46-2.46C2.33,17.84,2.33,16.87,2.33,14.93 V9.07c0-1.94,0-2.92,0.33-3.96c0.41-1.14,1.31-2.04,2.46-2.46c1.05-0.33,2.01-0.33,3.96-0.33h5.86c1.94,0,2.92,0,3.96,0.33 c1.14,0.41,2.04,1.31,2.46,2.46c0.33,1.04,0.33,2.01,0.33,3.96V14.93z'
      />
      <circle fill='none' cx='12.93' cy='9.28' r='1.13' />
      <path
        fill='none'
        d='M19.76,10.87c0-4.43-2.6-6.44-6.04-6.44c-1.54,0-2.91,0.41-3.97,1.17c-0.36,0.25-0.84,0.01-0.84-0.42 c0-0.29-0.23-0.53-0.53-0.53H5.41c-0.29,0-0.53,0.23-0.53,0.53v13.5c0,0.29,0.23,0.53,0.53,0.53h3.16c0.29,0,0.52-0.23,0.52-0.52 v-0.79c0-5.89-3.11-10.29-0.04-10.87c2.81-0.53,6.01-0.28,6.05,1.88c0,0.19,0.03,0.75,0.81,1.05c0.47,0.19,1.18,0.24,2.12,0.22 c0,0,0.85-0.07,0.85,0.8c0,1.08-1.91,1-1.91,1c-0.63,0.03-2.12-0.14-2.97,0.11c-0.45,0.14-0.84,0.39-1.08,0.85 c-0.39,0.78-0.58,2.48-0.61,4.27v1.45c0,0.29,0.23,0.53,0.53,0.53h6.37c0.29,0,0.53-0.23,0.53-0.53l0,0V10.87z'
      />
      <path
        fill='currentColor'
        d='M21.34,5.12c-0.41-1.14-1.31-2.04-2.46-2.46c-1.04-0.33-2.01-0.33-3.96-0.33H9.07c-1.94,0-2.92,0-3.96,0.33 C3.97,3.07,3.07,3.97,2.66,5.12C2.33,6.17,2.33,7.13,2.33,9.07v5.86c0,1.94,0,2.92,0.33,3.96c0.41,1.14,1.31,2.04,2.46,2.46 c1.05,0.33,2.01,0.33,3.96,0.33h5.86c1.94,0,2.92,0,3.96-0.33c1.14-0.41,2.04-1.31,2.46-2.46c0.33-1.04,0.33-2.01,0.33-3.96V9.07 C21.67,7.13,21.67,6.16,21.34,5.12z M19.23,19.2h-6.37c-0.29,0-0.53-0.23-0.53-0.53v-1.45c0.03-1.78,0.22-3.49,0.61-4.27 c0.23-0.47,0.63-0.72,1.08-0.85c0.84-0.25,2.33-0.08,2.97-0.11c0,0,1.91,0.08,1.91-1c0-0.87-0.85-0.8-0.85-0.8 c-0.94,0.03-1.66-0.04-2.12-0.22c-0.78-0.31-0.81-0.86-0.81-1.05c-0.04-2.17-3.23-2.43-6.05-1.88c-3.07,0.58,0.04,5,0.04,10.87 v0.79c-0.01,0.29-0.23,0.52-0.52,0.52H5.41c-0.29,0-0.53-0.23-0.53-0.53v-13.5c0-0.29,0.23-0.53,0.53-0.53h2.97 c0.29,0,0.53,0.23,0.53,0.53c0,0.44,0.49,0.67,0.84,0.42c1.07-0.77,2.44-1.17,3.97-1.17c3.44,0,6.04,2.01,6.04,6.44v7.8l0,0 C19.76,18.96,19.52,19.2,19.23,19.2z M11.79,9.28c0-0.63,0.51-1.13,1.13-1.13s1.13,0.51,1.13,1.13s-0.51,1.13-1.13,1.13 S11.79,9.92,11.79,9.28z'
      />
    </g>
  </svg>
)
