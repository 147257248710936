export enum Kind {
  Metadata = 0,
  Text = 1,
  RecommendRelay = 2,
  Follows = 3,
  EncryptedDirectMessage = 4,
  EventDeletion = 5,
  Repost = 6,
  Reaction = 7,
  BadgeAward = 8,
  Media = 20,
  ChannelCreation = 40,
  ChannelMetadata = 41,
  ChannelMessage = 42,
  ChannelHideMessage = 43,
  ChannelMuteUser = 44,
  Blank = 255,
  Mutelist = 10000,
  Comment = 1111,
  Report = 1984,
  ZapRequest = 9734,
  ZapReceipt = 9735,
  Highlight = 9802,
  RelayList = 10002,
  ClientAuth = 22242,
  // replaceable
  BadgeDefinition = 30008,
  ProfileBadge = 30009,
  Article = 30023,
  RelayDiscovery = 30166,
  BookmarkList = 10003,
  MonitorDiscovery = 10166,
  NostrConnect = 24133,
}
